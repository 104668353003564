import React, { createContext, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import createPersistedState from 'use-persisted-state';
import propTypes from 'prop-types';

import useTranslationMessages from './hooks/useTranslationMessages';

const defaultLanguage = 'en';

// Local storage for users language they have set.
const usePersistedLocale = createPersistedState('HalaSystems-SelectedLanguage');

export const LanguageContext = createContext({});

export default function LocaleProvider({
  children,
  extendedMessages,
  onError,
}) {
  const [locale, setLocale] = usePersistedLocale(defaultLanguage);
  const translationMessages = useTranslationMessages(locale, extendedMessages);

  const contextValue = useMemo(
    () => ({ locale, setLocale }),
    [locale, setLocale]
  );

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider
        onError={onError}
        messages={translationMessages}
        locale={locale}
        defaultLocale={defaultLanguage}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

LocaleProvider.propTypes = {
  children: propTypes.node.isRequired,
  extendedMessages: propTypes.object,
  onError: propTypes.func,
};

LocaleProvider.defaultProps = {
  extendedMessages: {},
  onError: (f) => f,
};
