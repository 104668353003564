import { useMemo } from 'react';
import merge from 'lodash/merge';
import * as messagesPerLanguage from '../translations';

export default function useTranslationMessages(locale, extendedMessages) {
  const store = useMemo(
    () => merge({}, messagesPerLanguage, extendedMessages),
    [extendedMessages]
  );

  return useMemo(() => store[locale], [locale, store]);
}
