import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export default function useTranslation() {
  const intl = useIntl();

  return useCallback(
    (key, defaultFallbackText) => {
      if (!key) {
        throw new Error('Missing required key.');
      }
      return intl.formatMessage({
        id: key,
        defaultMessage: defaultFallbackText || key,
      });
    },
    [intl]
  );
}
